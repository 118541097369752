<template>
    <div class="md-modal">
        <h1 class="reward__title">{{isUpdateMode ? $t('btSave') : $t('btAdd')}}</h1>
        <ap-form
            :fields="fields"
            :values="currency"
            :buttons="buttons"
            @change="save"
            @close="$emit('close')"
        ></ap-form>
    </div>
</template>

<script>
    import ApForm from "../../components/ApForm";

    export default {
        name: 'UpdateCurrency',
        props: ['id'],
        components: {
            ApForm
        },
        data() {
            return {

            }
        },
        computed: {
            // Form mode
            isUpdateMode() {
                // Return mode
                return this.id ? 1 : 0;
            },
            fields() {
                let fields = {
                    code: {
                        type: 'string',
                        title: 'Code',
                        validation: ["required"]
                    },
                    name: {
                        type: 'string',
                        title: 'Name',
                        validation: ["required"]
                    },

                }
                return fields
            },
            buttons() {
                // Buttons list
                let btns = {close: true};

                // Save button
                btns['save'] = {
                    title: this.isUpdateMode ? this.$t('btSave') : this.$t('btAdd'),
                    class: "md-primary md-raised",
                    type: "submit"
                }

                // Return list
                return btns;
            },
            currency() {
                let val = {}
                if (this.id) {
                    val = this.$get('exchange/getCurrency', this.id)
                }


                return val
            }

        },
        methods: {
            async save(values) {
                await this.$dispatch('exchange/currencyUpdate', values)
                this.$emit('close')
            },
        }
    }
</script>
