<template>
    <div>
        <md-dialog
            :md-active.sync="showDialog"
            :md-fullscreen="false"
            class="popup-dialog"
        >
            <update-currency
                :id="currency_id"
                templateMode="true"
                @close="showDialog=false"
            />
        </md-dialog>
        <md-table v-model="currencies">
            <md-table-toolbar>
                <h1 class="md-title">Currencies</h1>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="ID" md-sort-by="id" md-numeric><a href="#" @click.prevent="update(item)">{{item.id}}</a></md-table-cell>
                <md-table-cell md-label="Code" md-sort-by="id">{{item.code}}</md-table-cell>
                <md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
                <md-table-cell md-label="Action">
                    <md-button @click="deleteItem(item)">
                        <md-icon>delete_outline</md-icon>
                    </md-button>
                </md-table-cell>
            </md-table-row>
        </md-table>


        <md-button class="md-fab md-primary md-fab-bottom-right" @click="add">
            <md-icon>add</md-icon>
        </md-button>
    </div>
</template>

<script>
import UpdateCurrency from "./UpdateCurrency";
    export default {
        name: 'Currencies',
        components: {UpdateCurrency},
        data() {
            return {
                currency_id: 0,
                showDialog: false
            }
        },
        created() {
            this.$dispatch('exchange/loadCurrencies')
        },
        computed: {
            currencies() {
                return this.$get('exchange/getCurrencies')
            }
        },
        methods: {
            deleteItem(item) {
                this.$dispatch('exchange/currencyDelete', item.id)
            },
            add() {
                this.currency_id = 0
                this.showDialog = true
                // pass
            },
            update(item) {
                this.currency_id = item.id
                this.showDialog = true
            }
        }
    }
</script>
